import React, { useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import {
  Nav,
  Navbar,
  NavItem
} from 'react-bootstrap'
import { Domain_Name } from "../../constant";
import { useHover } from 'usehooks-ts';
import Image from "react-bootstrap/Image";
import { LinkContainer } from 'react-router-bootstrap';
import useWindowDimensions from "./useWindowDimensions";

export default function Menu(props) {
  const navRef = React.useRef('hide');

  const hoverRef = useRef(null);
  const isHover = useHover(hoverRef);

  const { width } = useWindowDimensions();

  const [hovered, setHovered] = useState(false);
  const toggleHover = () => setHovered(!hovered);
  const [toggleClass, setToggleClass] = useState(false);

  function handleClick() {
    if(width <= 991){
      navRef.current.classList.toggle('show');
      navRef.current.classList.toggle('hide');
      setToggleClass((toggleClass) => !toggleClass);
      document.body.classList.toggle('overflowHidden') ;
    }
  }

  let toggleClassCheck = toggleClass ? 'show' : 'hide';

  return (
    <>
      <Navbar expand="lg" className="p-0">
        <Container>
          <Navbar.Toggle onClick={handleClick}>
            <span className="icon-bar m-0"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </Navbar.Toggle>
          <LinkContainer to="/">
            <Image
              src={`${Domain_Name}/images/logo.png`}
              height={65} width={40.5}
              className='mx-auto cursor-pointer mobileLogo'
            />
          </LinkContainer>
          <div className={`nav-bar collapse navbar-collapse ${toggleClassCheck}`} id="basic-navbar-nav" ref={navRef}>
            <Nav className="justify-content-center">
              <LinkContainer to="/">
                <NavItem className="nav-link" onClick={handleClick}>HOME</NavItem>
              </LinkContainer>
              <LinkContainer to="/whiskeys">
                <NavItem className="nav-link" onClick={handleClick}>Our Whiskeys</NavItem>
              </LinkContainer>
              <LinkContainer to="/whiskey-drinks">
                <NavItem className="nav-link" onClick={handleClick}>Our Recipes</NavItem>
              </LinkContainer>
              <LinkContainer to="/where-to-buy-seagrams">
                <NavItem className="nav-link" onClick={handleClick}>Where to Buy</NavItem>
              </LinkContainer>

              {/* For mobile menu */}
              <LinkContainer to="/whiskeys/seagrams-blended-whiskey" className='forMobileMenu'>
                <NavItem className="nav-link" onClick={handleClick}>&#62; Seagram's 7 Crown Blended Whiskey</NavItem>
              </LinkContainer>
              <LinkContainer to="/whiskeys/seagrams-honey-whiskey" className='forMobileMenu'>
                <NavItem className="nav-link" onClick={handleClick}>&#62; Seagram's 7 Crown Dark Honey</NavItem>
              </LinkContainer>
              <LinkContainer to="/whiskeys/seagrams-apple-whiskey" className='forMobileMenu'>
                <NavItem className="nav-link" onClick={handleClick}>&#62; Seagram's 7 Crown Apple</NavItem>
              </LinkContainer>
              {/* For mobile menu */}

              <LinkContainer to="/">
                <Image
                  src={`${Domain_Name}/images/logo.png`}
                  height={65} width={40.5}
                  className='descLogo mx-2 cursor-pointer'
                  onClick={handleClick}
                />
              </LinkContainer>
              {/* <LinkContainer to="/where-to-buy-seagrams">
                <NavItem className="nav-link" onClick={handleClick}>Where to Buy</NavItem>
              </LinkContainer> */}
              <LinkContainer to="/newsletter">
                <NavItem className="nav-link" onClick={handleClick}>Join Now</NavItem>
              </LinkContainer>
              <LinkContainer to="/contact-us">
                <NavItem className="nav-link" onClick={handleClick}>Contact Us</NavItem>
              </LinkContainer>
              <NavItem className="nav-link social-icon defaultImg"
                onMouseEnter={toggleHover}
                onMouseLeave={toggleHover}
              >
                <div ref={hoverRef} className={isHover ? 'iconHide' : ''} >
                  {
                    isHover && (<ul className="social" onClick={handleClick}>
                      <li>
                        <a social-btn="twitter" url="{{url}}" href="https://www.instagram.com/seagrams7" target="_blank" rel="noreferrer">
                          <Image src={`${Domain_Name}/images/icon-social-is-insta.svg`} alt="insta icon" />
                        </a>
                      </li>
                      <li>
                        <a social-btn="facebook" url="{{url}}" href="https://www.facebook.com/Seagrams-7-811915025605751/" target="_blank" rel="noreferrer">
                          <Image src={`${Domain_Name}/images/icon-social-recipe-fb.svg`} alt="Facebook icon" />
                        </a>
                      </li>
                    </ul>)
                  }
                  <span>Follow Us</span>
                </div>
              </NavItem>
              <NavItem className="nav-link social-icon changeImg"
                onMouseEnter={toggleHover}
                onMouseLeave={toggleHover}
              >
                <div className={hovered ? 'iconHide' : ''}>
                  <span>Follow Us</span>
                  <ul className="social">
                    <li>
                      <a social-btn="twitter" url="{{url}}" href="https://www.instagram.com/seagrams7" target="_blank" rel="noreferrer">
                        <Image src={`${Domain_Name}/images/icon-social-is-white.svg`} alt="insta icon" />
                      </a>
                    </li>
                    <li>
                      <a social-btn="facebook" url="{{url}}" href="https://www.facebook.com/Seagrams-7-811915025605751" target="_blank" rel="noreferrer">
                        <Image src={`${Domain_Name}/images/icon-social-fb-white.svg`} alt="Facebook icon" />
                      </a>
                    </li>
                  </ul>
                </div>
              </NavItem>
            </Nav>
            <LinkContainer to="/regularsrules" className="btn-header-regularsrules">
              <NavItem onClick={handleClick} className="nav-link btn-dive-bar-header">#REGULARSRULES</NavItem>
            </LinkContainer>
          </div>
        </Container>
      </Navbar>
    </>
  );
}