import React, { useEffect, useState } from "react";
import { Col, Row, Button, Container } from "react-bootstrap";
import {
  redirect,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Domain_Name } from "../../constant";
import Image from "react-bootstrap/Image";
import parse from "html-react-parser";
import { Helmet, HelmetProvider } from "react-helmet-async";
import NotFound from "./404";
import { motion } from "framer-motion";

export default function Recipes(props) {
  const params = useParams();
  const location = useLocation(); // React Hook
  const checkRoute = location.pathname;
  let urlSlug = `${params.slug}`;
  const drinksJsonApi = Domain_Name + "/recipes.json";
  const navigate = useNavigate();
  //set initial values empty to array
  const [data, setData] = useState([]);
  const [temp, setTemp] = useState(false);
 

  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
  }, []);

  //function for set data to variable
  const getData = async () => {
    await fetch(drinksJsonApi, {
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        setData(myJson);
        setTemp(true);
      });
  };

  const getTitleModified = (title_h1) => {
    var istitleWithNumber = title_h1.startsWith("7");
    return istitleWithNumber
      ? parse(title_h1.slice(2).replaceAll(" ", "_").toLowerCase())
      : "";
  };

  //filter by slug
  const whisky = data;
  const data_array = data ? data : [];

  let elementPos = data_array
    .map(function (x) {
      return x.slug;
    })
    .indexOf(urlSlug);

  let ImgID = "img-" + "" + elementPos;

  let objectFound = data_array[elementPos];

  if (urlSlug === "undefined") {
    objectFound = data_array[0];
  }

  const filtered = data_array.filter((data) => {
    return data.slug === urlSlug;
  });

  const notFound = {
    404: {
      url: "/404",
      title: "404",
      description:
        "Sorry, it appears the page you were looking for doesn’t exist anymore or may have moved. Go back to our Home Page to explore more.",
      keywords: "",
      canonicalTag: "https://www.seagrams7.com/404",
      primaryImage: "",
      slug: "404",
    },
  };

  

  if (filtered.length === 0 && urlSlug !== 'undefined') {
    return (
      <div>
        <NotFound notFoundKey={notFound && notFound["404"]} />
      </div>
    );
  }

  let next = () => {
    let array_length = data_array.length;
    if (elementPos === array_length - 1) {
      elementPos = 0;
      objectFound = data_array[elementPos];
      navigate(`../whiskey-drinks/${objectFound.slug}`, { replace: true });
    } else {
      if (elementPos === -1) {
        elementPos = 0;
      }
      elementPos = elementPos + 1;
      objectFound = data_array[elementPos];
      navigate(`../whiskey-drinks/${objectFound.slug}`, { replace: true });
    }
  };

  let pre = () => {
    let array_length = data_array.length;
    elementPos = elementPos - 1;
    if (elementPos < 0) {
      elementPos = array_length - 1;
      objectFound = data_array[elementPos];
      navigate(`../whiskey-drinks/${objectFound.slug}`, { replace: true });
    } else {
      objectFound = data_array[elementPos];
      navigate(`../whiskey-drinks/${objectFound.slug}`, { replace: true });
    }
  };

  // let routUrl = Domain_Name + checkRoute;
  let routUrl = Domain_Name + checkRoute;
  const url = encodeURIComponent(routUrl);
  const facebookUrl = "https://www.facebook.com/sharer/sharer.php?u=" + routUrl;

  /*
   * While first time load page call data from appData.json
   * When next slide then call data from recipes.json (objectFound)
   */

  const propData =
    props && (props.recipesDataKey === "" ? objectFound : props.recipesKey);
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>
            {propData &&
              (propData.seoTitle === undefined
                ? propData.title
                : propData.seoTitle)}
          </title>
          <meta
            name="description"
            content={
              propData &&
              (propData.description === ""
                ? propData.seoDescription
                : propData.description)
            }
          />
          <meta
            name="keywords"
            content={
              propData &&
              (propData.keywords ? propData.keywords : propData.seoMeta)
            }
          />
          <link rel="icon" href="/favicon.ico" />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content="Seagram's 7" />
          <meta
            name="twitter:title"
            content={
              propData &&
              (propData.seoTitle === undefined
                ? propData.title
                : propData.seoTitle)
            }
          />
          <meta
            name="twitter:description"
            content={
              propData &&
              (propData.description === ""
                ? propData.seoDescription
                : propData.description)
            }
          />
          <meta
            property="og:title"
            content={
              propData &&
              (propData.seoTitle === undefined
                ? propData.title
                : propData.seoTitle)
            }
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={propData && propData.canonicalTag} />
          <meta
            property="og:image"
            content={`${Domain_Name + "/images/drinks/" + (propData && (propData.primaryImage ? propData.primaryImage : propData.heroImage))}`}
          />
          <meta
            property="og:description"
            content={
              propData &&
              (propData.description === ""
                ? propData.seoDescription
                : propData.description)
            }
          />
          <link rel="canonical" href={propData && propData.canonicalTag} />
        </Helmet>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 0.6,
            ease: [0, 0.71, 0.2, 1.01]
          }}
          exit={{ opacity: 0 }}
        >
          <section
            className={`whiskey-drinks-page pb-0 ${objectFound && getTitleModified(objectFound.title)
              }`}
          >
            <Container style={{ maxWidth: "1340px" }}>
              <Row>
                <Col xs={1} className="p-1">
                  <Button className="prev-btn p-0" onClick={pre}>
                    <Image
                      src="../images/arrow-left-plain.svg"
                      alt="notFound"
                      className="img-fluid"
                    />
                  </Button>
                </Col>
                <Col lg={4} md={3} sm={10} xs={10} className="text-center">
                  <Row className="mobileContent">
                    <Col sm={6} xs={6} lg={12} md={12}>
                      {objectFound && (

                        // objectFound.heroImage == "7-7.png" ? 
                        //   <Image
                        //     src={`${Domain_Name}/images/drinks/${objectFound.heroImage}`}
                        //     alt={`${objectFound.imageAlt}`}
                        //     className="img-fluid d-inline-block"
                        //   />
                        // :

                        <Image
                          src={`${Domain_Name}/images/drinks/${objectFound.heroImage}`}
                          alt={`${objectFound.imageAlt}`}
                          className={`img-fluid d-inline-block ${ImgID}`} id={ImgID}
                         style={{maxWidth: "65%"}}
                         
                        />
                      )}
                    </Col>
                    <Col sm={6} xs={6} className="d-flex align-items-center">
                      <section className="title forMobile">
                        <h2>Whiskey Drinks</h2>
                        <h1 className="lead changeColor">
                          {objectFound && parse(objectFound.title)}
                        </h1>
                        <div className="mt-3">
                          <a
                            href={void (0)}
                            onClick={() => navigate("/where-to-buy-seagrams")}
                            className="btn btn-lg"
                          >
                            BUY NOW{" "}
                            <Image src="../images/arrow.svg" alt="arrow" />
                          </a>
                          <ul className="social d-flex mt-4">
                            <li>
                              <a
                                social-btn="facebook"
                                url={url}
                                href={facebookUrl}
                                target="_blank"
                              >
                                <Image
                                  src={`${Domain_Name}/images/icon-social-recipe-fb.svg`}
                                  alt="Facebook icon"
                                />
                              </a>
                            </li>
                            <li>
                              <a
                                social-btn="twitter"
                                url={url}
                                href={`https://twitter.com/intent/tweet?text=Channeling%20my%20inner%20bartender%20with%20this%20Seagram's%207%20recipe%2C%20the%20%23${objectFound &&
                                  objectFound.title.replace(/<\/?[^>]+(>|$)/g, "")
                                  }&url=${url}&original_referer=${url}`}
                                target="_blank"
                              >
                                <Image
                                  src={`${Domain_Name}/images/icon-social-recipe-tw.svg`}
                                  alt="Twitter icon"
                                />
                              </a>
                            </li>
                            <li>
                              <a
                                social-btn="pinterest"
                                url={url}
                                href={
                                  "https://www.pinterest.com/pin/create/button/?url=" +
                                  url +
                                  `&media=https://www.seagrams7.com/images/drinks/${objectFound && objectFound.heroImage
                                  }&description=I%20know%20what%20cocktail%20I%27ll%20be%20mixing%20up%20this%20weekend%2C%20thanks%20to%20Seagram's%207!`
                                }
                                target="_blank"
                              >
                                <Image
                                  src={`${Domain_Name}/images/icon-social-recipe-pt.svg`}
                                  alt="Pinterest icon"
                                />
                              </a>
                            </li>
                            <li>
                              <a
                                social-btn="email"
                                url={url}
                                href={`mailto:?subject=${objectFound &&
                                  encodeURIComponent(
                                    objectFound.title.replace(
                                      /<\/?[^>]+(>|$)/g,
                                      ""
                                    )
                                  )
                                  }&body=Let%27s%20try%20this%20recipe%2C%20the%20${objectFound &&
                                  encodeURIComponent(
                                    objectFound.title.replace(
                                      /<\/?[^>]+(>|$)/g,
                                      ""
                                    )
                                  )
                                  }.%20It%20sounds%20delicious!%20${url}`}
                              >
                                <Image
                                  src={`${Domain_Name}/images/icon-social-recipe-email.svg`}
                                  alt="Email icon"
                                />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </section>
                    </Col>
                  </Row>
                </Col>
                <Col xs={1} className="text-end forMobile p-1">
                  <Button className="next-btn p-0" onClick={next}>
                    <Image
                      src="../images/arrow-left-plain.svg"
                      alt="notFound"
                      className="img-fluid"
                    />
                  </Button>
                </Col>

                <Col lg={6} md={7} sm={12} className="pb-4">
                  <section className="title forDekstop">
                    <h2>Whiskey Drinks</h2>
                    <h1 className="lead changeColor">
                      {objectFound && parse(objectFound.title)}
                    </h1>
                  </section>
                  <section className="ingredients hidden-xs mt-4">
                    <h1 className="lead changeColor">Ingredients</h1>
                    <ul>
                      {objectFound &&
                        objectFound.ingredients.map((value, i) => {
                          return (
                            <li key={i}>
                              <span className="changeColorA">{parse(value)}</span>
                            </li>
                          );
                        })}
                    </ul>
                  </section>
                  <section className="method hidden-xs mt-4">
                    <h1 className="lead changeColor">How to Make</h1>
                    <ul className="p-0" style={{ listStyleType: "none" }}>
                      {objectFound &&
                        objectFound.instructions.map((value, i) => {
                          return (
                            <li key={i}>
                              <b>{i + 1}.</b>{" "}
                              <span className="changeColorA">{parse(value)}</span>
                            </li>
                          );
                        })}
                    </ul>
                  </section>
                  <div className="forDekstop">
                    <a
                      href={void (0)}
                      onClick={() => navigate("/where-to-buy-seagrams")}
                      className="btn btn-lg"
                    >
                      BUY NOW <Image src="../images/arrow.svg" alt="arrow" />
                    </a>
                    <ul className="social d-flex mt-4">
                      <li>
                        <a
                          social-btn="facebook"
                          url={url}
                          href={facebookUrl}
                          target="_blank"
                        >
                          <Image
                            src={`${Domain_Name}/images/icon-social-recipe-fb.svg`}
                            alt="Facebook icon"
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          social-btn="twitter"
                          url={url}
                          href={`https://twitter.com/intent/tweet?text=Channeling%20my%20inner%20bartender%20with%20this%20Seagram's%207%20recipe%2C%20the%20%23${objectFound &&
                            objectFound.title.replace(/<\/?[^>]+(>|$)/g, "")
                            }&url=${url}&original_referer=${url}`}
                          target="_blank"
                        >
                          <Image
                            src={`${Domain_Name}/images/icon-social-recipe-tw.svg`}
                            alt="Twitter icon"
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          social-btn="pinterest"
                          url={url}
                          href={
                            "https://www.pinterest.com/pin/create/button/?url=" +
                            url +
                            `&media=https://www.seagrams7.com/images/drinks/${objectFound && objectFound.heroImage
                            }&description=I%20know%20what%20cocktail%20I%27ll%20be%20mixing%20up%20this%20weekend%2C%20thanks%20to%20Seagram's%207!`
                          }
                          target="_blank"
                        >
                          <Image
                            src={`${Domain_Name}/images/icon-social-recipe-pt.svg`}
                            alt="Pinterest icon"
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          social-btn="email"
                          url={url}
                          href={`mailto:?subject=${objectFound &&
                            encodeURIComponent(
                              objectFound.title.replace(/<\/?[^>]+(>|$)/g, "")
                            )
                            }&body=Let%27s%20try%20this%20recipe%2C%20the%20${objectFound &&
                            encodeURIComponent(
                              objectFound.title.replace(/<\/?[^>]+(>|$)/g, "")
                            )
                            }.%20It%20sounds%20delicious!%20${url}`}
                        >
                          <Image
                            src={`${Domain_Name}/images/icon-social-recipe-email.svg`}
                            alt="Email icon"
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col xs={1} className="text-end forDekstop p-1">
                  <Button className="next-btn p-0" onClick={next}>
                    <Image
                      src="../images/arrow-left-plain.svg"
                      alt="notFound"
                      className="img-fluid"
                    />
                  </Button>
                </Col>
              </Row>
            </Container>
          </section>
        </motion.div>
      </HelmetProvider>
    </>
  );
}
