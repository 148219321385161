import React, { useEffect, useState } from "react";
import { Col, Row, Button, Container } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { Domain_Name } from "../../constant";
import Image from "react-bootstrap/Image";
import { Helmet, HelmetProvider } from "react-helmet-async";
import NotFound from "./404";
import { motion } from "framer-motion";
export default function WhiskeysCarousel() {

  const params = useParams();
  const urlSlug = `${params.slug}`;

  const navigate = useNavigate();

  //set initial values empty to array
  const [data, setData] = useState([]);
  const [status, setSatus] = useState(false);

  const drinksJsonApi = Domain_Name + "/drinks.json";
  //function for set data to variable
  const getData = async () => {
    await fetch(drinksJsonApi, {
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        setData(myJson);
        setSatus(true);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
  }, []);

  const data_array = data ? data : [];

  let elementPos = data_array
    .map(function (x) {
      return x.slug;
    })
    .indexOf(urlSlug);

  let objectFound = data_array[elementPos];

  const filtered = data_array.filter((data) => {
    return data.slug === urlSlug;
  });

  const notFound = {
    404: {
      url: "/404",
      title: "404",
      description:
        "Sorry, it appears the page you were looking for doesn’t exist anymore or may have moved. Go back to our Home Page to explore more.",
      keywords: "",
      canonicalTag: "https://www.seagrams7.com/404",
      primaryImage: "",
      slug: "404",
    },
  };

  if (filtered.length === 0 && urlSlug !== 'undefined') {
    return (
      <div>
        {
          status ? <NotFound notFoundKey={notFound && notFound["404"]} /> : ""
        }
      </div>
    );
  }

  let next = () => {
    let array_length = data_array.length;
    if (elementPos === array_length - 1) {
      elementPos = 0;
      objectFound = data_array[elementPos];
      navigate(`../whiskeys/${objectFound.slug}`, { replace: true });
    } else {
      elementPos = elementPos + 1;
      objectFound = data_array[elementPos];
      navigate(`../whiskeys/${objectFound.slug}`, { replace: true });
    }
  };

  let pre = () => {
    let array_length = data_array.length;
    elementPos = elementPos - 1;
    if (elementPos < 0) {
      elementPos = array_length - 1;
      objectFound = data_array[elementPos];
      navigate(`../whiskeys/${objectFound.slug}`, { replace: true });
    } else {
      objectFound = data_array[elementPos];
      navigate(`../whiskeys/${objectFound.slug}`, { replace: true });
    }
  };

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>
            {objectFound && objectFound.seoTitle}
          </title>
          <meta
            name="description"
            content={
              objectFound && objectFound.seoDescription
            }
          />
          <meta
            name="keywords"
            content={
              objectFound && objectFound.seoKeywords
            }
          />
          <link rel="icon" href="/favicon.ico" />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content="Seagram's 7" />
          <meta
            name="twitter:title"
            content={
              objectFound && objectFound.seoTitle
            }
          />
          <meta
            name="twitter:description"
            content={
              objectFound && objectFound.seoDescription
            }
          />
          <meta
            property="og:title"
            content={
              objectFound && objectFound.seoTitle
            }
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={objectFound && objectFound.canonicalTag} />
          <meta
            property="og:image"
            content={`${Domain_Name + "/images/flavor/" + objectFound.seoImage}`}
          />
          <meta
            property="og:description"
            content={
              objectFound && objectFound.seoDescription
            }
          />
          <link rel="canonical" href={objectFound && objectFound.canonicalTag} />
        </Helmet>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 0.6,
            ease: [0, 0.71, 0.2, 1.01]
          }}
          exit={{ opacity: 0 }}
        >
          <section className={objectFound && `${objectFound.slug} whiskeys-page`}>
            <Container>
              <div>
                <Row>
                  <Col xs={1} className="p-1">
                    <Button className="prev-btn p-0" onClick={pre}>
                      <Image
                        src="../images/arrow-left-plain.svg"
                        alt="notFound"
                        className="img-fluid"
                      />
                    </Button>
                  </Col>
                  <Col lg={4} md={3} xs={3} className="text-center">
                    {objectFound && (
                      <Image
                        src={`${Domain_Name}/images/flavor/${objectFound.image}`}
                        className="img-fluid brandImg"
                      />
                    )}
                  </Col>
                  <Col lg={6} md={7} xs={7}>
                    <section className="title">
                      <h1 className="lead changeColor">
                        {objectFound && `${objectFound.fullName}`}
                      </h1>
                      <p className="description">
                        {objectFound && `${objectFound.description}`}
                      </p>
                       <p className="othertext">
                        {objectFound && `${objectFound.othertext}`}
                      </p>
                    </section>
                    <section className="features row forDekstop">
                      <Row>
                        <Col sm={6}>
                          <h1 className="lead changeColor">Tasting Features</h1>
                          <h4 className="">Nose</h4>
                          <p>{objectFound && `${objectFound.nose}`}</p>
                          <h4 className="">Taste</h4>
                          <p>{objectFound && `${objectFound.taste}`}</p>
                          <div className="drink-finish">
                            <h4 className="">Finish</h4>
                            <p>{objectFound && `${objectFound.finish}`}</p>
                          </div>

                          <a
                            href={void (0)}
                            onClick={() => navigate("/where-to-buy-seagrams")}
                            className="btn btn-lg"
                          >
                            BUY NOW <Image src="../images/arrow.svg" alt="arrow" />
                          </a>
                        </Col>
                        <Col sm={6}>
                          <h1 className="lead changeColor">Featured Cocktail</h1>
                          <div className="card">
                            {objectFound && (
                              <Image
                                src={`${Domain_Name}/images/${objectFound.featuredimage}`}
                                className="img-fluid feature-img"
                              />
                            )}
                            <div className="card-body px-0 pb-0">
                              <a
                                href={void (0)}
                                onClick={() =>
                                  navigate(
                                    "../whiskey-drinks/" + objectFound.featuredUrl,
                                    { replace: true }
                                  )
                                }
                                className="stretched-link"
                                style={{ textDecoration: "none" }}
                              >
                                <h5 className="card-title">
                                  {objectFound && `${objectFound.featuredtitle.toUpperCase()}`}
                                </h5>
                              </a>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </section>
                  </Col>
                  <Col xs={1} className="p-1 text-end">
                    <Button onClick={next} className="next-btn p-0">
                      <Image
                        src="../images/arrow-left-plain.svg"
                        alt="notFound"
                        className="img-fluid"
                      />
                    </Button>
                  </Col>
                </Row>
                <section className="features forMobile mt-4">
                  <Row>
                    <Col xs={6}>
                      <h1 className="lead changeColor">Tasting Features</h1>
                      <h4 className="">Nose</h4>
                      <p>{objectFound && `${objectFound.nose}`}</p>
                      <h4 className="">Taste</h4>
                      <p>{objectFound && `${objectFound.taste}`}</p>
                      <div className="drink-finish">
                        <h4 className="">Finish</h4>
                        <p>{objectFound && `${objectFound.finish}`}</p>
                      </div>
                      <a
                        href={void (0)}
                        onClick={() => navigate("/where-to-buy-seagrams")}
                        className="btn btn-lg"
                      >
                        BUY NOW<Image src="../images/arrow.svg" alt="arrow" />
                      </a>
                    </Col>
                    <Col xs={6}>
                      <h1 className="lead changeColor">Featured Cocktail</h1>
                      <div className="card">
                        {objectFound && (
                          <Image
                            src={`${Domain_Name}/images/${objectFound.featuredimage}`}
                            className="img-fluid feature-img"
                          />
                        )}
                        <div className="card-body px-0 pb-0">
                          <a
                            href={void (0)}
                            onClick={() =>
                              navigate(
                                "../whiskey-drinks/" + objectFound.featuredUrl,
                                { replace: true }
                              )
                            }
                            className="stretched-link"
                            style={{ textDecoration: "none" }}
                          >
                            <h5 className="card-title">
                              {objectFound && `${objectFound.featuredtitle.toUpperCase()}`}
                            </h5>
                          </a>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </section>
              </div>
            </Container>
          </section>
        </motion.div>
      </HelmetProvider>
    </>
  );
}